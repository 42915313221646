// colors
$primary: rgb(109, 100, 235);
$secondary: #eb6464;
$green: #00a800;
$xwhite: #fafafa;
$project-bg: #394354;
$gray: #5e5e5e;
$font-mono: 'Source Code Pro';

// breakpoints

$breakpoint-tablet: 768px;
$breakpoint-mobile: 576px;
