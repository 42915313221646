[data-aos="fade-custom"] {
  transform: translateZ(0) scale(0.95);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateZ(0) scale(1);
    opacity: 1;
  }
}

[data-aos="slide-right-offscreen"] {
  transform: translate(-1000px) scale(1);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translate(0) scale(1);
    opacity: 1;
  }
}