@use 'variables' as *;
@import 'mixins';

.button {
  padding: 0.5rem;
  font-size: 16px;
  border-radius: 14px;
  font-weight: 600;
  display: flex;
  gap: 0.3rem;
  text-decoration: none;
  transition: all 100ms;
  cursor: pointer;

  @include breakpoint(sm) {
    font-size: 20px;
  }

  .btn-icon {
    width: 20px;
    fill: $xwhite
  }
  
}

.github-button {
  background-color: #6d64eb;
  color: white;
  box-shadow: 1px 1px 6px #6d64eb62;

  &:hover {
    transform: scale(1.02);
    box-shadow: 1px 1px 10px 0px #6d64eb62;
  }
}

.visit-button {
  background-color: #00a800;
  color: white;
  box-shadow: 1px 1px 6px #00a8007a;
  &:hover {
    transform: scale(1.02);
    box-shadow: 1px 1px 10px 0px #00a8007a;
  }
}
