@mixin breakpoint($size) {
  @if $size == sm {
    @media (min-width: 640px) {
      @content;
    }
  } @else if $size == md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $size == lg {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $size == xl {
    @media (min-width: 1280px) {
      @content;
    }
  }
}

@mixin reducedMotion() {
  @media (prefers-reduced-motion: reduce) {
    @content;
  }
}
