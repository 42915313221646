@use 'reset';
@use 'variables' as *;
@use 'utils';
@use 'nav';
@use './sections/footer';
@use './sections/header';
@use 'tech-stack';
@use 'project';
@use 'animations';
@use './projects/najkempy';
@use './projects/firesim';
@use './projects/battleship';
@use './projects/tunneler';
@use './projects/toolsy';
@use './projects/memoryCard';
@use './projects/simple-todo';
@use './projects/react-cart';
@use './projects/portfolio';
@use './sections/contact';
@use './sections/about';
@use './sections/lightbox';
@use 'swiper';
@use 'buttons';
@import 'mixins';
@import "hamburgers-settings";
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600;700&family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: 'Source Code Pro', monospace;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  line-height: 1.3;
  color: $xwhite;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: underline;
  color: #6D64EB;
  text-decoration-color: #6D64EB;
}


.unordered-list {
  list-style-type: disc;
  padding-left: 1rem;

  li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

button {
  padding: 5px 8px;
  border: none;
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  color: $xwhite;
  @include breakpoint(sm) {
    font-size: 21px;
  }
}
