@use "variables" as *;
@import 'mixins';

.techstack {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    cursor: default;
  }

  ul > li {
    display: flex;
    font-size: 18px;
    align-items: center;
    gap: 3px;
    background-color: rgb(235, 233, 233);
    color: rgb(37, 37, 37);
    width: fit-content;
    border-radius: 10px;
    font-weight: 400;
    padding: 4px 5px;
    @include breakpoint(sm) {
      padding: 6px;
    }
  }

  .tech-stack-icon {
    width: auto;
    height: 20px;
  }
}
