@import 'mixins';


.swiper {
  padding: 20px!important;
  width: 100%;
  margin-bottom: 0.5rem;
  //max-height: 300px;
  height: 200px;
  @include breakpoint(sm) {
    height: 300px;
  }

  .swiper-button-next, .swiper-button-prev {
    color: white;
    text-shadow: 0 0 2px #00000099;
    transition: transform ease-in-out 0.25s;
  }
  
  .swiper-button-disabled {
    transform: scale(0);
    opacity: 1!important;
  }
  
  .gradient {
    display: none;
    background: linear-gradient(
      90deg, #f2f2f2, transparent);
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 35px;
          height: auto;
          z-index: 5;
  }
}

.swiper-slide img {
  user-select: none;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.swiper-slide {
  box-shadow: 2px 2px 15px 0px #0000002b;
  width: auto!important;
  max-width: 100%!important;
/*   width: fit-content !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center; */
}
