@import '../mixins';
@import '../variables';

#najkempy {
  //background-color: rgb(230, 230, 230);
  background: linear-gradient(90deg, #55ad89, #00a862);

  .logo {
    max-width: 340px;
    margin: 0rem auto 0rem auto;
  }

  h1 {
    font-size: 1.4rem;
    color: $gray;
    @include breakpoint(sm) {
      font-size: 1.7rem;
    }
  }

  .custom-shape-divider-top .shape-fill {
    fill: #6d64eb;
  }

  .custom-shape-divider-bottom svg {
    position: relative;
    display: block;
    //width: calc(202% + 1.3px);
    width: 100%;
    height: 60px;
  }

  .tent {
    position: absolute;
    filter: opacity(0.5);

    left: -128px;
    top: -90px;
    width: 200px;
    transform: rotate(346deg);
  }

  .animate-sway-tree {
    @include reducedMotion{
      animation: none;
    }
    transform-origin: bottom;
    animation: sway-tree 5s ease-in-out infinite;
  }

  @keyframes sway-tree {
    0% {
      transform: skewX(-0.8deg);
    }
    50% {
      transform: skewX(0.8deg);
    }
    100% {
      transform: skewX(-0.8deg);
    }
  }

  .tree {
    position: absolute;
    //filter: opacity(0.3);
    z-index: 10;
    &:nth-child(1) {
      left: -189px;
      bottom: -15px;
      width: 313px;
      filter: brightness(0.9);
      z-index: 5;
    }
    &:nth-child(2) {
      left: -377px;
      bottom: -17px;
      width: 302px;
    }
    &:nth-child(3) {
      left: -492px;
      bottom: -15px;
      width: 254px;
      filter: brightness(0.9);
      z-index: 5;
    }
    &:nth-child(4) {
      left: -736px;
      bottom: -15px;
      width: 329px;
    }
    &:nth-child(5) {
      left: -912px;
      bottom: -15px;
      width: 203px;
    }
    &:nth-child(6) {
      right: -243px;
      bottom: -15px;
      width: 380px;
    }
    &:nth-child(7) {
      right: -393px;
      bottom: -17px;
      width: 293px;
      filter: brightness(0.9);
      z-index: 5;
    }
    &:nth-child(8) {
      right: -516px;
      bottom: -15px;
      width: 231px;
    }
    &:nth-child(9) {
      right: -745px;
      bottom: -15px;
      width: 309px;
      filter: brightness(0.95);
      z-index: 5;
    }
    &:nth-child(10) {
      right: -912px;
      bottom: -15px;
      width: 233px;
    }
  }
}
