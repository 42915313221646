@import '../mixins';
@import '../variables';

#toolsy {
  background:  linear-gradient(90deg, #89ece3, #4fd1c5);

  .logo {
    max-width: 280px;
    margin: auto;
  }

  .toolsy-icon {
    position: absolute;
  }
  
  .custom-shape-divider-top {
    z-index: 50;
  }

}
