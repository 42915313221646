@use 'variables' as *;
@import 'mixins';

.nav-wrapper {
  transform: translate(0, -80px);
  height: 78px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: fixed;
  box-shadow: 0 0 10px #534ace8f;
  z-index: 1000;
  justify-content: space-between;
  font-family: $font-mono;
  transition: transform 0.5s ease-in-out;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(14px);
    background-color: #3b33a6c7;
  }

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: #3b33a6;
  }

  &.show {
    transform: translate(0, 0);
  }

  @include breakpoint(sm) {
    flex-direction: row;
  }

  .mobile-menu-icon {
    width: 46px;
    fill: $xwhite;
    opacity: 0.75;
  }
}

.nav-special-wrap {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;

  @include breakpoint(sm) {
    flex-basis: 0;
    flex-grow: 1;
  }
}

nav {
  align-self: flex-end;
  padding: 1.2rem 0.3rem;
  display: flex;
  position: relative;
  background-color: #0000006b;
  z-index: 10;
  box-shadow: 0 0 10px #00000052;
  transform: scale(0);
  opacity: 0;
  transform-origin: top right;
  overflow: hidden;
  transition: transform 0.4s, opacity 0.4s;

  @include breakpoint(sm) {
    display: flex;

    padding: 0.5rem 0.3rem;
    background-color: transparent;
    box-shadow: none;

    transition: none !important;
    transform: scale(1) !important;
    opacity: 1 !important;
  }
  font-size: 1.2rem;
  align-items: center;

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin: 0;
    list-style: none;

    @include breakpoint(sm) {
      flex-direction: row;
      padding: 0.5rem;
    }
  }

  li {
    color: $xwhite;
    display: inline-block;
    // font-size: 1.5rem;
  }

  a {
    color: $xwhite;
    padding: 6px 12px;
    text-decoration: none;
  }
}

.show-mobile-menu {
  transform: scale(1);
  opacity: 1;
  background-color: #312e52;
  padding: 1.2rem 0.7rem;
}

.show-mobile-menu ul {
  gap: 0.8rem;
}
