@use 'variables' as *;
@import 'mixins';

.window-height {
  min-height: 100vh;
}

.monospace {
  font-family: 'Source Code Pro';
}

.flex {
  display: flex;
}

.bg-primary {
  background-color: $primary;
}
.bg-secondary {
  background-color: $secondary;
}

.have-p-padded {
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.decoration {
  display: none;
  user-select: none;
  @include breakpoint(lg) {
    display: block;
  }
}

.decoration-wrap {
  position: relative;
  width: fit-content;
  margin: auto;
}