@use 'variables' as *;
@import 'mixins';

.projectv2 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  color: #434343;
  font-size: 20px;
  padding: 5rem 0.6rem 2rem 0.6rem;
  @include breakpoint(sm) {
    padding: 7rem 1rem 1rem 1rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  .subheading {
    //font-family: 'Source Code Pro';
    //letter-spacing: -1px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: 500;
    color: $gray;
    @include breakpoint(sm) {
      font-size: 1.6rem;
    }
  }

  .heading {
    //font-family: "Source Code Pro";
    letter-spacing: -1px;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0.3rem;
    text-align: center;
    @include breakpoint(sm) {
      font-size: 3.3rem;
    }
  }

  h2 {
    font-family: 'Source Code Pro';
    letter-spacing: -1px;
    font-weight: 600;
    font-size: 1.4rem;
    //margin-bottom: 0.5rem;
    text-transform: uppercase;

    @include breakpoint(sm) {
      font-size: 1.6rem;
    }
  }

  .swiper-pagination-bullet {
    background-color: #d8d8d8;
  }

  .infoblock {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .btn-icon {
    width: 20px;
    fill: #fafafa;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    flex-wrap: wrap;
  }

  .techstack li {
    background-color: #ffffff;
    box-shadow: 1px 1px 3px #0000003d;
  }

  .wrap {
    position: relative;
    z-index: 30;
    max-width: 960px;
    background-color: #f2f2f2;
    border-radius: 10px;
    box-shadow: 2px 4px 8px #00000052;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: auto;
    padding: 2rem 1.5rem;
    @include breakpoint(sm) {
      padding: 2rem 2.5rem;
    }
  }

  .custom-shape-divider-top {
    margin-top: -1px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-bottom {
    margin-bottom: -1px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top svg {
    position: relative;
    display: block;
    width: 100%;
    height: 40px;
    @include breakpoint(sm) {
      height: 80px;
    }
  }
}
