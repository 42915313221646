@use 'variables' as *;

$hamburger-layer-color: $xwhite;
$hamburger-padding-x: 5px !default;
$hamburger-padding-y: 5px !default;
$hamburger-layer-width: 30px !default;
$hamburger-hover-opacity       : 1 !default;

.hamburger {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  z-index: 50;
  display: flex !important;
  @include breakpoint(sm) {
    display: none !important;
  }
}
