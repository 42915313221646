@use '../variables' as *;
@import '../mixins';

#contact {
  color: #424242;
  padding: 5rem 2rem;
  background: linear-gradient(286deg, #dee8ff, #eaf2ff);
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include breakpoint(lg) {
    font-size: 22px;
  }

  .contact-wrap {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  p {
    margin-bottom: 0.4rem;
    font-family: 'Source Code Pro';
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    text-align: center;
  }


  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #e06c75;
    margin-bottom: 0.8rem;
    @include breakpoint(sm) {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0;
    }

    .icon-wrap {
      background-color: white;
      padding: 20px;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      box-shadow: 1px 2px 6px #00000026;
      margin-bottom: 16px;
    }

    .grid-item a{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }


  .contact-icon {
  }
  
}
